import React from "react"

export interface IDateEvent {
  date: string
  description: string
}
export const DateEvent = ({ date, description }: IDateEvent, index: number) => {
  return (
    <p key={`${date}-${index}`} className="date-event">
      <b>{date}</b> <span>{description}</span>
    </p>
  )
}
