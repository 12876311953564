import React, { useEffect } from "react"

export const Mailto = () => {
  const coded = "aXGqX.7X9@HaXqA.TDa"
  const key = "AwehxTJU1P0dRtonausWLB7b3i4pk69g8DyXQzrOCYVlqFMKcSHZIG5fjvEmN2"

  const shift = coded.length
  let link = ""
  for (let i = 0; i < coded.length; i++) {
    if (key.indexOf(coded.charAt(i)) === -1) {
      link += coded.charAt(i)
    } else {
      const ltr = (key.indexOf(coded.charAt(i)) - shift + key.length) % key.length
      link += key.charAt(ltr)
    }
  }
  return (
    <a href={`mailto:${link}`} alt="Email to me">
      {link}
    </a>
  )
}
