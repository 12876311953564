import React from "react"

const WorkImage = (id: number) => (
  <img key={id} alt={`Pic${id}`} src={require(`../images/pic${id}.jpg`)} className="art-item" />
)
const Images = () => (
  <div className="image">
    {[20, 21, 22, 16, 17, 18, 19, 11, 14, 12, 15, 13, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(WorkImage)}
  </div>
)

export const Work = () => {
  return (
    <div className="group">
      <a name="work" />
      <h2>WORK</h2>
      <Images />
    </div>
  )
}
