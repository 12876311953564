import React from "react"

import { Articles } from "../components/Articles"
import { Biography } from "../components/Biography"
import { Contact } from "../components/Contact"
import { Group } from "../components/Group"
import { Information } from "../components/Information"
import Layout from "../components/layout"
import { Navigation } from "../components/Navigation"
import SEO from "../components/seo"
import { VideoArt } from "../components/VideoArt"
import { Work } from "../components/Work"

const IndexPage = () => (
  <Layout>
    <SEO title="Artist Mayia Hadjigeorgiou" />
    <div id="content">
      <div id="whitespace" />
      <div className="with-border">
        <Biography />
        <Group name="education" />

        <VideoArt />

        <Group name="upcoming-events" big={true} />
        <Group name="exhibitions" />

        <Work />
        <Articles />

        <Contact />
      </div>
    </div>

    <Information />
    <Navigation />
  </Layout>
)

export default IndexPage
