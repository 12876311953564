import React from "react"
import file from "../../content/biography.json"

export const Biography = () => {
  return (
    <div className="group">
      <a name="biography" />
      <h2>BIOGRAPHY</h2>
      <p>{file.intro}</p>
    </div>
  )
}
