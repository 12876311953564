import React, { FunctionComponent } from "react"
import { DateEvent, IDateEvent } from "./DateEvent"

interface IGroupProps {
  name: string
  collection?: IDateEvent[]
  big?: boolean
}

export const Group = ({
  name,
  collection = require(`../../content/${name}.json`).events || [],
  big = false,
}: IGroupProps) => {
  if (collection.length === 0) return null

  return (
    <div className="group date-group">
      <a name={name.toLowerCase()} />
      <Header big={big}>{name.replace("-", " ").toUpperCase()}</Header>
      {collection.map(DateEvent)}
    </div>
  )
}

const Header: FunctionComponent<{ big: boolean }> = ({ children, big }) =>
  big ? <h2>{children}</h2> : <h3>{children}</h3>
