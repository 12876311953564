import React from "react"

const Survivant = () => (
  <video className="art-item" controls={true} loop={false} autoPlay={false}>
    <source src={require(`../videos/SURVIVANT-2-720p.mp4`)} type="video/mp4" />
  </video>
)

const SurvivantRose = () => (
  <iframe
    className="art-item"
    width="560"
    height="337"
    src="https://www.youtube-nocookie.com/embed/xdabcKv4zyQ?autoplay=0&modestbranding=1&rel=0&controls=0fs=0"
    frameBorder="0"
    allowFullScreen={true}
  />
)

const Videos = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    }}
  >
    <Survivant />
    <SurvivantRose />
  </div>
)

export const VideoArt = () => {
  return (
    <div className="group">
      <a name="video-art" />
      <h2>Video Art</h2>
      <Videos />
    </div>
  )
}
