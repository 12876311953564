import React from "react"
import { address, email, phone } from "../../content/address.json"
import { Mailto } from "./Mailto"

export const Information = () => {
  return (
    <div id="information" style={{ position: "fixed" }}>
      <span className="normal_font">Artist</span>
      <h1>
        MAYIA
        <br />
        HADJIGEORGIOU
      </h1>
      <p>
        <span dangerouslySetInnerHTML={{ __html: address }} />
        <br />
        <Mailto />
        <br />
        phone {phone}
      </p>
    </div>
  )
}
