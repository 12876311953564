import React from "react"
interface INavItem {
  title: string
  linkId: string
}
const upcomingEvents = require(`../../content/upcoming-events.json`).events || []

const NavItem = ({ title, linkId }: INavItem) => {
  if (!title) return null
  return (
    <li key={linkId}>
      <a href={`#${linkId}`}>{title}</a>
    </li>
  )
}

const navItem = (linkId: string, title: string = linkId) => ({
  title: title.toUpperCase(),
  linkId: linkId.toLowerCase(),
})

export const Navigation = () => {
  const links: INavItem[] = [
    navItem("biography"),
    navItem("education"),
    upcomingEvents.length ? navItem("upcoming-event", "upcoming event") : navItem(""),
    navItem("video-art", "video art"),
    navItem("exhibitions"),
    navItem("work"),
    navItem("articles"),
    navItem("contact"),
  ]
  return <div id="navigation">{links.map(NavItem)}</div>
}
