import React from "react"

export const Contact = () => {
  const returnAdress = "https://www.artmayia.com/?thanks#contact"
  const success = typeof window !== `undefined` ? window.location.search.endsWith("thanks") : false

  return (
    <div className="group">
      <form action="https://formsubmit.co/d0eeb5f8265dd22718985b6d6e72cf2d" method="POST">
        <input type="hidden" name="_subject" value="Somebody wrote a message!" />
        <input type="text" name="_honey" style={{ display: "none" }} />
        <input type="hidden" name="_next" value={returnAdress} />
        <input type="hidden" name="_replyto" />

        <div style={{ margin: 0, padding: 0, display: "inline" }}>
          <a name="contact" />
          <h2>CONTACT</h2>
          <p>
            <label>Your Email</label>
            <br />
            <input type="email" name="email" />
          </p>

          <p>
            <label>Your message</label>
            <br />
            <textarea style={{ width: "70%", height: 120 }} name="message" />
          </p>
          <p>
            {!success && <input type="submit" value="send" />}
            {success && <span className="thank-you">Thank you!</span>}
          </p>
        </div>
      </form>
    </div>
  )
}
