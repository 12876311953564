import React from "react"
import { articles } from "../../content/articles.json"
interface IArticle {
  href: string
  text: string
}

const Article = ({ href, text }: IArticle, index: number) => {
  return (
    <p className="article" key={index}>
      <a href={href} target="_blank" rel="unfollow">
        {text}
      </a>
    </p>
  )
}

export const Articles = () => {
  if (articles.length === 0) {
    return null
  }
  return (
    <div className="group">
      <a name="articles" />
      <h3>ARTICLES</h3>
      {articles.map(Article)}
    </div>
  )
}
